import Vue from "vue";
import VueRouter from "vue-router";

// 页面数据
import IndexBase from "../components/IndexBase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/data_class"
  },
  {
    path: "/",
    name: "IndexBase",
    component: IndexBase,
    children: [
      {
        path: "/data_class",
        name: "DataClass",
        component: () => import("../views/DataClass")
      },
      {
        path: "/api_management",
        name: "ApiManagement",
        component: () => import("../views/ApiManagement")
      },
      {
        path: "/user_management",
        name: "UserManagement",
        component: () => import("../views/UserManagement")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (!window.localStorage.getItem("userInfo") && to.path !== "/login") {
    next("/login");
  }
  next();
});

export default router;
