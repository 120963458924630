<template>
  <el-container id="IndexBase">
    <el-header class="pc">
      <span>{{ $constant.web.webName }}</span>
      <el-dropdown @command="userTool">
        <span
          >{{ $constant.method.getUserInfo()["username"]
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">修改密码</el-dropdown-item>
          <el-dropdown-item command="1">切换用户</el-dropdown-item>
          <el-dropdown-item command="2">安全退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-header class="mobile">
      <el-menu
        mode="horizontal"
        router
        :default-active="$route.path"
        background-color="#393d49"
        text-color="#fff"
        active-text-color="#009688"
      >
        <el-menu-item index="/data_class">
          <i class="el-icon-menu"></i>
          <span slot="title">数据分类</span>
        </el-menu-item>
        <el-menu-item index="/api_management">
          <i class="el-icon-menu"></i>
          <span slot="title">API管理</span>
        </el-menu-item>
        <el-menu-item
          index="/user_management"
          v-if="$constant.method.getUserInfo()['is_admin']"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container>
      <el-aside width="auto">
        <el-menu
          class="pc"
          router
          :default-active="$route.path"
          background-color="#393d49"
          text-color="#fff"
          active-text-color="#009688"
        >
          <el-menu-item index="/data_class">
            <i class="el-icon-menu"></i>
            <span slot="title">数据分类</span>
          </el-menu-item>
          <el-menu-item index="/api_management">
            <i class="el-icon-menu"></i>
            <span slot="title">API管理</span>
          </el-menu-item>
          <el-menu-item
            index="/user_management"
            v-if="$constant.method.getUserInfo()['is_admin']"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view class="router-view"></router-view>
      </el-main>
    </el-container>

    <el-dialog title="修改密码" :visible.sync="setPasswordShow" width="300px">
      <el-form size="mini">
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入密码"
            type="password"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请确认密码"
            type="password"
            v-model="form.confirmPassword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="add"
            >提交</el-button
          >
          <el-button size="mini" plain @click="this.setPasswordShow = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: "IndexBase",
  data() {
    return {
      setPasswordShow: false,
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  methods: {
    userTool(command) {
      if (command == 0) {
        this.setPasswordShow = true;
      }
      if (command == 1) {
        this.$router.push("/login");
      }
      if (command == 2) {
        window.localStorage.removeItem("userInfo");
        this.$router.push("/login");
      }
    },
    add() {
      if (!this.form.password || !this.form.confirmPassword) {
        this.$message.error("请完善表单");
        return false;
      }
      if (this.form.password != this.form.confirmPassword) {
        this.$message.error("密码不一致");
        return false;
      }
      if (this.form.password.length < 6) {
        this.$message.error("密码不能少于6位");
        return false;
      }
      this.$http({
        url: "/user/modify_password",
        method: "POST",
        data: {
          id: this.$constant.method.getUserInfo()["id"],
          password: this.form.password
        }
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
        this.setPasswordShow = false;
      });
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  #IndexBase .pc {
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  #IndexBase .mobile {
    display: none !important;
  }
  #IndexBase .el-aside {
    height: calc(100vh - 60px);
  }
  #IndexBase .el-menu:not(.el-menu--collapse) {
    width: 200px;
    height: 100%;
  }
}
#IndexBase .el-header.pc {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  line-height: 60px;
  background-color: #23262e;
}
#IndexBase .el-header.mobile {
  padding: 0 !important;
}
#IndexBase .el-header .el-dropdown {
  color: #ffffff;
}
</style>
