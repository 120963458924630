import Vue from "vue";
import Axios from "axios";
import Constant from "../constant";
import { Message } from "element-ui";

let http = Axios.create({
  baseURL: Constant.web.baseURL
});
http.interceptors.response.use(config => {
  if (config.data.code != 1) {
    Message.error(config.data.msg);
    return Promise.reject(config.data.msg);
  } else {
    return config.data.data;
  }
});

Vue.prototype.$http = http;
