import Vue from "vue";

let index = {
  web: {
    // baseURL: "http://localhost",
    baseURL: "/index.php",
    webName: "云数据中心",
    pageSizes: [10, 30, 50, 100]
  },
  method: {
    getUserInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
    getArrayValueByKey(arr, key = "id") {
      let keyArr = [];
      arr.map(item => {
        keyArr.push(item[key]);
      });
      return keyArr;
    }
  }
};

Vue.prototype.$constant = index;

export default index;
